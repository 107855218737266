import React, { useState, useRef, useEffect } from 'react';
import './App.scss';

import { initializeApp } from 'firebase/app';
import { getAnalytics, setUserProperties } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

//import { collection, query, orderBy, limit, addDoc, serverTimestamp } from 'firebase/firestore';
import { collection, query, orderBy, limit, doc, setDoc, serverTimestamp } from 'firebase/firestore';

import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const ResponseLanguages = {
	gb: 'English',
	fr: 'Français',
	tz: 'Kiswahili',
	de: 'Deutsch'
};

const app = initializeApp({
  apiKey: "AIzaSyAHGC_XusPyWHj8ZNiu-Mi7rf892G2qTHc",
  authDomain: "superchat-d5ea6.firebaseapp.com",
  projectId: "superchat-d5ea6",
  storageBucket: "superchat-d5ea6.appspot.com",
  messagingSenderId: "595895941397",
  appId: "1:595895941397:web:50859f55c9bcbdfca6d11c",
  measurementId: "G-EYEQ2TM35X"
})

const analytics = getAnalytics(app);
const userAgent = window.navigator.userAgent;
setUserProperties(analytics, { browser: userAgent });

const auth = getAuth(app)
const firestore = getFirestore(app);
const MessageLimit = 50

function App() {
	const [user] = useAuthState(auth);

      //<header className="App-header">
      //</header>
			//	<header>
			//		<div>AI chat</div>
			//		<SignOut />
			//	</header>
			//<section>
			//</section>
    //<div className="App"> </div>
  return (
		<>
			{user ? <ChatRoom /> : <FrontPage />}
		</>
  );
}

function FrontPage() {
	return(
		<div className='App FrontPage'>
			<SignIn />
		</div>
	)
}

function SignIn() {
	const signInWithGoogle = () => {
		const provider = new GoogleAuthProvider();
		signInWithPopup(auth, provider);
	}

	return(
		<button className='sign-in' onClick={signInWithGoogle}>Sign in with Google</button>
	)
}

function SignOut() {
	return auth.currentUser && (
		<button className='sign-out' onClick={() => auth.signOut()}>
<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M180-120q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h291v60H180v600h291v60H180Zm486-185-43-43 102-102H375v-60h348L621-612l43-43 176 176-174 174Z"/></svg>
</button>
	)
}


function FlagDialog({ isOpen, onClose }) {
	const dialogRef = useRef();

  useEffect(() => {
    if (isOpen) {
      dialogRef.current.showModal();
				} else {
			if (dialogRef.current) { dialogRef.current.close();}
    }
  }, [isOpen]);

  //if (!isOpen) {
   // return null;
  //}
	console.log("DIALOG " + isOpen)

	return (
		<dialog ref={dialogRef} className='FlagDialog'>
			<button onClick={()=>onClose(null)}>X</button>
			<div className='languages'>
				{Object.keys(ResponseLanguages).map((lang) => (
					<div key={lang} onClick={()=>onClose(lang)}>
						<span className={`fi fi-${lang}`}></span><p>{ResponseLanguages[lang]}</p>
					</div>
				))}
			</div>
		</dialog>
	)
}


function ChatRoom() {
	const { uid } = auth.currentUser;
	const messagesRef = collection(firestore, 'users', uid, 'messages');
	const q = query(messagesRef, orderBy('createdAt', 'desc'), limit(MessageLimit));
	const [formValue, setFormValue] = useState('');
	const [hasFlagDialog, setFlagDialog] = useState(false);
	const [lang, setLang] = useState('gb');

	const [messages, loading] = useCollectionData(q);
	const dummy = useRef();

	const [isWaiting, setIsWaiting] = useState(false);

	useEffect(() => {
		if (messages) {
			//setIsWaiting(messages.length && messages[messages.length - 1].uid === auth.currentUser.uid)
			setIsWaiting(messages.length && messages[0].uid === auth.currentUser.uid)
			dummy.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [messages]);

	const sendMessage = async(e) => {
		e.preventDefault();
		//console.log("send message - start");

		const trimmedValue = formValue.trim();
		if (!trimmedValue) { return; }

		const { uid, photoURL } = auth.currentUser;
		const newDocRef = doc(messagesRef)
		//console.log("send message - before set doc");
		await setDoc(newDocRef, {
			text: trimmedValue,
			createdAt: serverTimestamp(),
			id: newDocRef.id,
			responseLang: lang,
			uid,
			photoURL,
		});
		setFormValue('');
	}

	const onCloseDialog = (lang) => {
		if (lang) { setLang(lang); }
		setFlagDialog(false);
	}

	return (
		<div className='App ChatRoom'>
			<div className="top-bar">
				<button className='flag-selector' onClick={() => setFlagDialog(true)}>
					<span className={`fi fi-${lang}`}></span>
				</button>
				<SignOut />
			</div>
			<main>
				{messages && [...messages].reverse().map((msg, idx) =>  {
					return <ChatMessage message={msg} key={idx}/>;
				})}
				<div className={isWaiting ? "message dummy waiting" : "message dummy hidden"}><p></p></div>
				<span ref={dummy} />
			</main>
			<form onSubmit={sendMessage} >
				<input value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Type message here..."/>
				<button type="submit">
					<svg xmlns="http://www.w3.org/2000/svg" height="96" viewBox="0 -960 960 960" width="96"><path d="M120-160v-640l760 320-760 320Zm60-93 544-227-544-230v168l242 62-242 60v167Zm0 0v-457 457Z" fill="white" /></svg>
				</button>
			</form>
			<FlagDialog isOpen={hasFlagDialog} onClose={onCloseDialog}/>
		</div>
	);
}

function ChatMessage(props) {
	const { text, uid, photoURL } = props.message;
	const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
	return (
		<div className={`message ${messageClass}`}>
			{messageClass === 'sent' ? <img src={photoURL} /> : <span />}
			<p>{text}</p>
		</div>
	)
}


export default App;
